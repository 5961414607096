import React from "react";
import logo from "../../Assets/logo.png";
import "./home.css";
import ContentWrapper from "../Contents/ContentWrapper/ContentWrapper";
import contents from "../Contents/content";
import Footer from "../Footer/Footer";

export default function Home() {
  return (
    <section className="HomeOuterBorder">
      <section className="headerWrapper">
        <img className="logo" src={logo} alt="sampurna-logo" />
        <div>
          <p className="PageTitle">Central Link Portal</p>
        </div>
      </section>
      <section className="contentBodyWrapper">
        {contents.map((cont) => {
          return (
            <ContentWrapper
              
              key={cont.id}
              category={cont.category}
              content={cont.content}
            />
          );
        })}
      </section>
      <Footer />
    </section>
  );
}
