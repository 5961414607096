import { useEffect } from "react";
import "./App.css";
import Home from "./Components/Home/Home";

function App() {
  
  /** Disabling Right Click */
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default right-click behavior
    };

    // Add event listener to the document for the contextmenu event
    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  /**Disabling Keyboard Shortcuts */

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Disable Ctrl+A (Cmd+A on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "a" || event.key === "A")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+S (Cmd+S on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "s" || event.key === "S")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+X (Cmd+X on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "x" || event.key === "X")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+C (Cmd+C on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "C")
      ) {
        event.preventDefault();
      }

      // Disable Ctrl+Shift+I (Cmd+Shift+I on Mac)
      if (
        (event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        (event.key === "i" || event.key === "I")
      ) {
        event.preventDefault();
      }
    };

    // Add event listener to the document for the keydown event
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  return (
    <>
      <Home />
    </>
  );
}

export default App;
