import React from "react";
import ContentList from "../ContentList/ContentList";
import './contentWrapper.css'

export default function ContentWrapper(props) {
  return (
    <>
      <section className="contentWrapperSec">
        <p className="contentBoxTitle">{props.category}</p>
        {
          props.content.map((data)=>{
            return <ContentList
              key = {data.id}
              linkTitle = {data.linkTitle}
              link = {data.link}
              icon = {data.icon}
              downloadable = {data.downloadable}
            />
          })
        }
      </section>
    </>
  );
}
