import React from 'react'
import '../Footer/footer.css'

export default function Footer() {
  return (
    <section className='footerOuterArea'>
      <footer>
        <div className='footerWrapper'>
            <p> Made With <span className='heartSymbol'>&#10084;</span> From Sampurna IT Team</p>
        </div>
      </footer>
    </section>
  )
}
