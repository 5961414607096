import React from 'react'
import './contentList.css'


export default function ContentList(props) {
  return (
    <section className='contentListWrapper'>
        <div>
            <img className="bulleteIcon" src={props.icon} alt='bulletPointIcon'/>
            <a href={props.link} target='_blank' download rel='noopener noreferrer'>{props.linkTitle}</a>
        </div>
    </section>
  )
}
